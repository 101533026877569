import React, { useContext, useState, useEffect } from 'react';
import { FirebaseContext } from './Firebase'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Link from "next/link"
import { Home } from "@styled-icons/feather/Home"
import { Menu, Transition } from '@headlessui/react'
import { Folder } from "@styled-icons/bootstrap/Folder"
import { SettingsOutline as Settings } from "@styled-icons/evaicons-outline/SettingsOutline"
import { ChevronDown } from "@styled-icons/heroicons-solid/ChevronDown"

const LoginOrProfile = (props) => {
    const router = useRouter()
    const auth = useContext(FirebaseContext)
    const { user } = auth
    const NotificationDropdown = dynamic(() => import("react-activity-feed").then(mod => mod.NotificationDropdown))
    const ProfileThumb = dynamic(() => import("./ProfileThumb"))
    const [userPublic, setUserPublic] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    let timeoutId = null

    useEffect(() => {
      if (localStorage.getItem("userPublic")) {
        setUserPublic(JSON.parse(localStorage.getItem("userPublic")))
      }
    }, [])

    useEffect(() => {
      if (auth.userPublic === null) return

      setUserPublic(auth.userPublic)
    }, [auth.userPublic])

    const handleMouseEnter = () => {
      if (window.matchMedia('(hover: hover)').matches) {
        if (timeoutId) clearTimeout(timeoutId)
        setIsOpen(true)
      }
    }

    const handleMouseLeave = () => {
      if (window.matchMedia('(hover: hover)').matches) {
        timeoutId = setTimeout(() => {
          setIsOpen(false)
        }, 100)
      }
    }

    const handleClick = () => {
      if (!window.matchMedia('(hover: hover)').matches) {
        setIsOpen(!isOpen)
      }
    }

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (isOpen && !event.target.closest('.profile-menu')) {
          setIsOpen(false)
        }
      }

      document.addEventListener('click', handleClickOutside)
      return () => document.removeEventListener('click', handleClickOutside)
    }, [isOpen])

    if(user === null && !userPublic) return <></>

    const menuItems = [
      { name: 'Dashboard', href: '/dashboard', icon: Home },
      { name: 'Imported Products', href: '/imported-products', icon: Folder },
      { name: 'Profile Settings', href: '/profile', icon: Settings },
    ]

    return (
      <>
        {(!user || user.isAnonymous) && !userPublic ? (
           <Link href="/profile" passHref prefetch={false}>
            <a className="flex items-center justify-center w-12 h-12 text-black rounded-full bg-pink border border-transparent  focus:outline-none hover:text-pink-700 transition-all duration-200 ease-in ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="none"
                className="pl-1 w-8 h-8 "
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M10 .423a5 5 0 0 1 2.424 9.374 8 8 0 0 1 5.572 7.376l.004.25v3H2v-3c0-3.574 2.343-6.6 5.577-7.627A5 5 0 0 1 10 .422m0 10c-4.472 0-7 3.694-7 7.003v1.997h14v-1.997c0-3.317-2.528-7.003-7-7.003m0-9a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
                  clipRule="evenodd"
                />
              </svg>
            </a>
           </Link>
        ) : (
          <div className="flex items-center">
            <div 
              className="relative inline-block text-left profile-menu"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              <div>
                <div className="flex items-center gap-1 focus:outline-none cursor-pointer group">
                  <div className="h-12 w-12 rounded-full transition-all duration-200">
                    <ProfileThumb userPublic={userPublic} linkToAccountAdmin={true} />
                  </div>
                  <ChevronDown className="w-4 h-4 text-gray-600 group-hover:text-pink-700 transition-all duration-200" />
                </div>
              </div>
              <Transition
                show={isOpen}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div 
                  className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none profile-menu"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="px-1 py-1">
                    <a
                      href="/interior-design-ai/designer"
                      className="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-900 hover:bg-pink-50 hover:text-pink-700"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="h-4 w-4 mr-2 text-pink-700" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M12 4v16m8-8H4" 
                        />
                      </svg>
                      <span>Design room</span>
                    </a>
                    <Link
                      href="/new-board"
                      passHref
                      legacyBehavior
                    >

                      <a
                        rel="noopener noreferrer"
                        className="group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium text-gray-900 hover:bg-pink-50 hover:text-pink-700"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4 mr-2 text-pink-700" 
                          fill="none" 
                          viewBox="0 0 24 24" 
                          stroke="currentColor"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M12 4v16m8-8H4" 
                          />
                        </svg>
                        <span>Create a board</span>
                      </a>
                    </Link>
                  </div>
                  <div className="px-1 py-1">
                    {menuItems.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-pink-50 hover:text-pink-700"
                      >
                        <item.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="px-1 py-1">
                    <button
                      onClick={() => auth.signout()}
                      className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-pink-50 hover:text-pink-700"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="mr-2 h-5 w-5" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" 
                        />
                      </svg>
                      Logout
                    </button>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        )}
      </>
    )
}

export default LoginOrProfile